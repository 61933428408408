import { useState, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import styles from "./App.module.scss";
import "./styles/index.scss";
import NavBar from "./components/NavBar/NavBar";

//images
import GlobalPaymentSolution from "./assets/images/global-payment-solution.png";
import TelegramIcon from "./assets/icons/icons8-telegram-2 1.png";
import WhatsappIcon from "./assets/icons/icons8-whatsapp 1.png";
import IMessageIcon from "./assets/icons/iMessage icon.png";
import GoogleBusinessChatIcon from "./assets/icons/google business chat logo 1.png";
import LoadingIcon from "./assets/icons/ezgif.com-crop.gif";

function App() {
  const { boot, shutdown } = useIntercom();
  const [showText, setShowText] = useState(true);
  

  useEffect(() => {
    setTimeout(() => {
      setShowText(!showText);
    }, 5000);
  }, [showText]);

  useEffect(() => {
    boot();

    return () => {
      shutdown();
    };
  }, []);

  return (
    <div className={styles.app}>
      <NavBar />
      <div className={styles.app__main}>
        <div className={styles.app__main__info}>
          <h2 className={styles.app__main__info__heading}>
            Send money instantly with the ease of a{" "}
            <div className={styles.app__main__info__heading__animation}>
              {showText ? <b>text</b> : <img src={LoadingIcon} />}
            </div>
            . Get started now!
          </h2>
          <p className={styles.app__main__info__desc}>
            Send funds in a snap using Whatsapp, Telegram, iMessage, and more.
            Quick, secure and convenient.
          </p>

          <div className={styles.app__main__info__socials}>
            <div
              onClick={() =>
                (window.location.href = window.location.hostname.includes("dev.") ? "https://t.me/BlaizRemittanceBot" : "https://t.me/BlaaizBot")
              }
              className={styles.app__main__info__social}
            >
              <img src={TelegramIcon} alt="telegram icon" />
              <h3>
                Send Money on <br /> <b>Telegram</b>
              </h3>
            </div>
            <div
              className={`${styles.app__main__info__social} ${styles.app__main__info__social__blurred}`}
            >
              <img src={WhatsappIcon} alt="whatsapp icon" />
              <h3>
                Send Money on <br /> <b>Whatsapp</b>
              </h3>
            </div>

            <div
              className={`${styles.app__main__info__social} ${styles.app__main__info__social__blurred}`}
            >
              <img src={IMessageIcon} alt="IMessage icon" />
              <h3>
                Send Money on <br /> <b>iMessage</b>
              </h3>
            </div>
            <div
              className={`${styles.app__main__info__social} ${styles.app__main__info__social__blurred}`}
            >
              <img src={GoogleBusinessChatIcon} alt="telegram icon" />
              <h3>
                Send Money on <br /> <b>Google Chat</b>
              </h3>
            </div>
          </div>
          <div />
        </div>
        <img
          className={styles.app__main__image}
          src={GlobalPaymentSolution}
          alt="an of a globe representing blaaiz's mission"
        />
      </div>
      <footer>
        <p>2023 Blaaiz. All rights reserved</p>
      </footer>
    </div>
  );
}

export default App;
