import React from "react";
import styles from "./NavBar.module.scss";

//images
import Logo from "../../assets/images/logo.png";

const NavBar = () => {
  return (
    <div style={{ paddingTop: window.location.hostname.includes("dev.") ? 30 : 0 }} className={styles.navBar}>
      { window.location.hostname.includes("dev.") ? <div style={{ position: "absolute", backgroundColor: "red", color: "white", width: "100%", top: 0, left: 0, textAlign: "center" }}>This is a development version</div> : null }
      <img src={Logo} className={styles.navBar__logo} />
    </div>
  );
};

export default NavBar;
